<div class="player-container">
    <div class="display" [class.visible]="playerService.getWavesurfer() && playerService.getWavesurfer().play" #display
        [@topAnimation]="!isCoverShowed ? 'shown' : 'hidden'">
        <div class="display__wrap" *ngIf="last_release">
            <img class="display__wrap__cover" #cover (click)="openCover()" [src]="last_release.cover_small" alt="Cover"/>
            <div class="display__wrap__wave" #wrap>
                <div class="display__wrap__wave__album">
                    <p>{{song.title}}</p>
                </div>
                <div class="display__wrap__wave__waveform">
                    <button aria-label="Button" mat-flat-button class="display__wrap__wave__waveform__init"
                        *ngIf="!playerService.getWavesurfer()" (click)="togglePlay()"
                        [matTooltip]="'PLAYER.PLAY' | translate">
                        <mat-icon class="display__wrap__wave__waveform__init__play">play_arrow</mat-icon>
                    </button>
                    <mat-spinner *ngIf="!isSongLoaded && playerService.getWavesurfer()" class="display__wrap__wave__waveform__spinner"></mat-spinner>
                </div>
            </div>
        </div>
        <div class="display__info" #info *ngIf="last_release">
            <p><span>{{'PLAYER.ALBUM' | translate}}:</span> {{last_release.title}}</p>
            <p *ngIf="last_release.showDate"><span>{{'PLAYER.DATE' | translate}}:</span> {{last_release.date}}</p>
            <p *ngIf="!last_release.showDate"><span>{{'PLAYER.DATE' | translate}}:</span> {{'PLAYER.SOON' | translate}}
            </p>
            <p *ngIf="translateService.currentLang == 'es'" #displayText>{{last_release.description_es}}</p>
            <p *ngIf="translateService.currentLang == 'en'" #displayText>{{last_release.description_en}}</p>
        </div>
        <div class="display__links">
            <app-links [last_release]="last_release"></app-links>
        </div>
    </div>
    <div class="player">

        <div class="player__wrap" *ngIf="viewInit">
            <!--<div class="player__wrap__loading" [class.active]="!playerService.isAudioLoaded" #loader></div>-->
            <button aria-label="Button" mat-icon-button aria-label="Prev" (click)="prevSong()" class="player__wrap__play-pause"
                [matTooltip]="'PLAYER.PREV' | translate">
                <mat-icon class="player__wrap__play-pause__play__prev">navigate_before</mat-icon>
            </button>
            <button aria-label="Button" mat-icon-button aria-label="Play" (click)="setCoverShowed()" class="player__wrap__play-pause"
                [matTooltip]="'PLAYER.ALBUM' | translate">
                <mat-icon *ngIf="!isCoverShowed" class="player__wrap__play-pause__play">bar_chart</mat-icon>
                <mat-icon *ngIf="isCoverShowed" class="player__wrap__play-pause__pause">bar_chart</mat-icon>
            </button>
            <button aria-label="Button" mat-icon-button aria-label="Play" (click)="togglePlay()" class="player__wrap__play-pause"
                [matTooltip]="playerService.getWavesurfer() ? ((!playerService.getWavesurfer().isPlaying() ? 'PLAYER.PLAY' : 'PLAYER.PAUSE') | translate) : playerService.currentTime == '0:00' ? ('PLAYER.PLAY' | translate) : null">
                <mat-icon *ngIf="!playerService.getWavesurfer()?.isPlaying()"
                    class="player__wrap__play-pause__play">play_arrow</mat-icon>
                <mat-icon *ngIf="playerService.getWavesurfer()?.isPlaying()"
                    class="player__wrap__play-pause__pause">pause</mat-icon>
            </button>
            <div class="player__wrap__timer" [ngClass]="{'active' : playerService.totalTime != '0:00'}">
                {{ playerService.currentTime }} / {{ playerService.totalTime }}
            </div>
            <button aria-label="Button" mat-icon-button aria-label="Next" (click)="nextSong()" class="player__wrap__play-pause"
                [matTooltip]="'PLAYER.NEXT' | translate">
                <mat-icon class="player__wrap__play-pause__play__next">navigate_next</mat-icon>
            </button>
        </div>
    </div>
</div>