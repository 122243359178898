<mat-toolbar color="primary" class="toolbar disable-selection">
    <div *ngIf="viewInit" class="toolbar__spacer__menu">
        <div class="toolbar__spacer__menu__container">
            <a aria-label="enlace" class="toolbar__spacer__menu__container__link" 
                (click)="handleListItemClick('/es/acerca', '/en/about')"
                (keyup.enter)="handleListItemClick('/es/acerca', '/en/about')"
                [routerLinkActiveOptions]="{ exact: true }"
                [routerLink]="getLanguage() == 'es' ? '/es/acerca' : '/en/about'"
                routerLinkActive="selected">{{'SIDENAV.ABOUT.TITLE' | translate}}</a>
            <a aria-label="enlace" class="toolbar__spacer__menu__container__link" 
                (click)="handleListItemClick('/es/lanzamientos', '/en/releases')"
                (keyup.enter)="handleListItemClick('/es/lanzamientos', '/en/releases')"
                [routerLinkActiveOptions]="{ exact: true }"
                [routerLink]="getLanguage() == 'es' ? '/es/lanzamientos' : '/en/releases'"
                routerLinkActive="selected">{{'SIDENAV.RELEASES.TITLE' | translate}}</a>
            <a aria-label="enlace" class="toolbar__spacer__menu__container__link" 
                (click)="handleListItemClick('/es/contacto', '/en/contact')"
                (keyup.enter)="handleListItemClick('/es/contacto', '/en/contact')"
                [routerLinkActiveOptions]="{ exact: true }"
                [routerLink]="getLanguage() == 'es' ? '/es/contacto' : '/en/contact'"
                routerLinkActive="selected">{{'SIDENAV.CONTACT.TITLE' | translate}}</a>
        </div>
    </div>
    <button aria-label="Button" mat-icon-button class="toolbar__menu" aria-label="Example icon-button with menu icon"
        (click)="sidenavToggle()">
        <mat-icon [@menubtn]="sidenavService.opened">menu</mat-icon>
    </button>
    <app-logo class="toolbar__logo"></app-logo>
    <div class="toolbar__spacer"></div>
    <app-language class="toolbar__language"></app-language>
</mat-toolbar>