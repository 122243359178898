import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LanguageGuard } from './@core/guards/language.guard';
import { AppComponent } from './app.component';
import { OnloadESGuard } from './@core/guards/onloadES.guard';
import { OnloadENGuard } from './@core/guards/onloadEN.guard';

const routes: Routes = [


  { 
    path: 'en', 
    loadChildren: () => import('./@views/en/home/home.module').then(m => m.HomeModule), 
    canActivate: [OnloadENGuard],
    data: { state: 'en' }
  },
  { 
    path: 'es', 
    loadChildren: () => import('./@views/es/inicio/inicio.module').then(m => m.InicioModule), 
    canActivate: [OnloadESGuard],
    data: { state: 'es' } 
  },
  { 
    path: 'en/about', 
    loadChildren: () => import('./@views/en/about/about.module').then(m => m.AboutModule), 
    canActivate: [OnloadENGuard],
    data: { state: 'about' } 
  },
  { 
    path: 'es/acerca', 
    loadChildren: () => import('./@views/es/acerca/acerca.module').then(m => m.AcercaModule), 
    canActivate: [OnloadESGuard],
    data: { state: 'acerca' } 
  },

  { 
    path: 'en/releases', 
    loadChildren: () => import('./@views/en/releases/releases.module').then(m => m.ReleasesModule), 
    canActivate: [OnloadENGuard],
    data: { state: 'releases' } 
  },
  { 
    path: 'es/lanzamientos', 
    loadChildren: () => import('./@views/es/lanzamientos/lanzamientos.module').then(m => m.LanzamientosModule), 
    canActivate: [OnloadESGuard],
    data: { state: 'lanzamientos' } 
  },

  { 
    path: 'en/contact', 
    loadChildren: () => import('./@views/en/contact/contact.module').then(m => m.ContactModule), 
    canActivate: [OnloadENGuard],
    data: { state: 'contact' } 
  },
  {
     path: 'es/contacto', 
     loadChildren: () => import('./@views/es/contacto/contacto.module').then(m => m.ContactoModule), 
     canActivate: [OnloadESGuard],
     data: { state: 'contacto' } 
    },

  {
    path: '**',
    canActivate: [LanguageGuard],    
    component: AppComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
