import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, ElementRef, HostListener, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { PlayerService } from '../../@core/services/player.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from './snackbar/snackbar.component';
import { TranslateService } from '@ngx-translate/core';
import { LastRelease, Song } from '../../@core/models/last-release.model';
import { MatDialog } from '@angular/material/dialog';
import { modalCoverComponent } from '../modal-cover/modal-covercomponent';
import { AboutService } from '../../@core/services/about.service';
import { ReleasesService } from '../../@core/services/releases.service';



@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrl: './player.component.scss',
  animations: [
    trigger('topAnimation', [
      state('shown', style({
        transform: 'translateY(0)'
      })),
      state('hidden', style({
        transform: 'translateY(-100%)'
      })),
      transition('hidden => shown', animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)')),
      transition('shown => hidden', animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)'))
    ]),
  ]
})

export class PlayerComponent implements OnInit, AfterViewInit {
  @ViewChild('display') display!: ElementRef;
  @ViewChild('wrap') wrap!: ElementRef;
  @ViewChild('cover') cover!: ElementRef;
  @ViewChild('volume') volume!: ElementRef;
  @ViewChild('time') time!: ElementRef;
  @ViewChild('loader') loader!: ElementRef;
  @ViewChild('info') info!: ElementRef;
  @ViewChild('displayText') displayText!: ElementRef;

  public top: number = 0;
  public volumeValue: number = 60;
  public viewInit: boolean = false;
  public isPlaying: boolean = false;
  public isCoverShowed: boolean = false;
  public loopActive: boolean = true;
  public isToggledescription: boolean = false;
  public currentTime: string = '0:00';
  public totalTime: string = '0:00';
  public currenTime: number = 0;
  public currentTimeSlider: number = 0;
  public formatLabelVolumeString: string = '';
  public displayHeight: number = 42;
  displayNone: boolean = false;
  displayNoneSubscription!: Subscription;
  songSubscription!: Subscription;
  private resizeSubs!: Subscription;
  private audioLoadedSubscription!: Subscription;
  private songChangedSubscription!: Subscription;
  public song!: Song;
  last_release!: LastRelease;
  isSongLoaded: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public playerService: PlayerService,
    public aboutService: AboutService,
    public releasesService: ReleasesService,
    private snackBar: MatSnackBar,
    public translateService: TranslateService,
    public dialog: MatDialog
  ) {

  }


  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    //this.setCover();

  }

  openCover(): void {
    //abrir modal aqui con la portada en grande en formato cuadrado con un mínimo para landscape 
    this.dialog.open(modalCoverComponent, {
      data: { cover: this.last_release.cover_big },
      panelClass: ['modalCover']
    });
  }
  openSnackBar(song: Song): void {
    const data = { data: song, cover: this.last_release.cover_big }
    this.snackBar.openFromComponent(SnackBarComponent, {
      data: data,
      duration: 3000,
      panelClass: ['snackbar'],
    });
  }

  ngOnInit(): void {
    this.getJsonData();

    this.songSubscription = this.playerService.getSongObservable().subscribe((song: Song) => {
      this.song = song;
    })
    this.audioLoadedSubscription = this.playerService.getisAudioLoadedObservable().subscribe((loaded: boolean) => {
      this.isSongLoaded = loaded;
      if (loaded) {
        this.openSnackBar(this.song)
      }
    })
    this.viewInit = true;
  }

  private getJsonData(): void {
    this.playerService.getLastReleasesJsonData().subscribe(data => {
      this.last_release = data.data;
      this.song = this.last_release.songs[0];
      this.playerService.setSongs(this.last_release.songs)
      this.playerService.setLastRelease(this.last_release);
      setTimeout(() => {       
        this.displayHeight = this.info.nativeElement.offsetHeight;
      }, 100);

    }, error => {
    });
    this.releasesService.getReleasesJsonData().subscribe(data => {
      this.releasesService.setReleases(data.data)
    }, error => {
    });
    this.aboutService.getAboutJsonData().subscribe(data => {
      this.aboutService.setAbout(data.data)
    }, error => {
    });
  }
  ngAfterViewInit(): void {
    //this.displayHeight = this.info.nativeElement.offsetHeight;
  }

  getHeightStyle(): any {
    this.displayText.nativeElement.style.height = this.displayText.nativeElement.offsetHeight + 'px';
  }

  ngOnDestroy(): void {
    this.resizeSubs?.unsubscribe();
    this.displayNoneSubscription?.unsubscribe();
    this.songChangedSubscription?.unsubscribe();
  }

  /*setCover(): void {
    if (this.wrap.nativeElement) {
      let heightWrap = this.wrap.nativeElement.offsetHeight;
      let heightInfo = this.info.nativeElement.offsetHeight;
      this.top = (heightWrap + 28) + heightInfo;
      if (this.isCoverShowed) {
        this.display.nativeElement.style.top = - this.top + 'px';
      }
    }
  }*/
  /* toggleText(): void {
     this.isToggleText =! this.isToggleText;
     if(this.isToggleText){
      // this.info.nativeElement.style.boxShadow = 'unset';
       this.displayText.nativeElement.style.webkitLineClamp = 'unset';
     } else {
     //  this.info.nativeElement.style.boxShadow = '0px 1px 0px 0px rgba(96,96,96,.5)';
       this.displayText.nativeElement.style.webkitLineClamp = 3;
 
     }
     setTimeout(() => {
       //this.getHeightStyle()
     }, 0);
   }*/
  setCoverBottom(top: number): void {
    if (this.wrap.nativeElement) {
      this.display.nativeElement.style.top = - top + 'px';
    }
    if (top == 0) {
      this.isCoverShowed = false;
    } else {
      this.isCoverShowed = true;
    }
  }


  prevSong(): void {
    let index = this.playerService.indexSong - 1;
    if (index < 0) {
      index = this.playerService.songs.length - 1
    }
    //if(this.playerService?.getIsPlaying() == true){
    this.playerService.preloadAudio(index);
    // } /*else {
    //  console.log(index)
    //  this.playerService.setIndexSong(index);
    // }*/
  }

  nextSong(): void {
    let index = this.playerService.indexSong + 1;
    if (index > this.playerService.songs.length - 1) {
      index = 0
    }
    // if(this.playerService?.getIsPlaying() == true){
    this.playerService.preloadAudio(index);
    // } /*else {
    //   console.log(index)
    //  this.playerService.setIndexSong(index);
    //  }*/
  }

  setCoverShowed(): void {
    this.isCoverShowed = !this.isCoverShowed
    if (!this.playerService.getWavesurfer()) {
      //this.playerService.preloadAudio(this.playerService.indexSong);
    }
  }
  togglePlay(): void {
    if (this.playerService.getWavesurfer()) {
      if (!this.playerService.getWavesurfer().isPlaying()) {
        if (this.currentTime == this.totalTime && !this.playerService.getWavesurfer().play) {
          this.setFinish();
        }
        this.playerService.play();
        this.isPlaying = true;
        /*if (this.wavesurfer.getCurrentTime() == 0) {
          this.setCoverBottom(this.top);
        }*/
      } else {
        this.playerService.pause();
        this.isPlaying = false;
        /*if (this.wavesurfer.getCurrentTime() == 0) {
          this.setCoverBottom(0);
        }*/
      }
    } else {
      this.song = this.playerService.songs[0];
      this.playerService.preloadAudio(this.playerService.indexSong);
      this.isPlaying = true;
    }

  }

  getIsPlaying(): boolean {
    if (this.playerService.getWavesurfer()) {
      return !this.playerService.getWavesurfer().pause;
    } else {
      return false;
    }
  }

  stop(): void {
    if (this.playerService.getWavesurfer()) {
      this.playerService.pause();
      this.playerService.setTime(0);
      //this.setCoverBottom(0);
      this.isCoverShowed = false;
    }
  }

  increaseVolume(): void {
    //if (this.playerService.getWavesurfer() && this.playerService.getWavesurfer().getVolume() <= 0.9) {
    this.playerService.increaseVolume();
    // }
  }

  decreaseVolume(): void {
    //if (this.playerService.getWavesurfer() && this.playerService.getWavesurfer().getVolume() >= 0.1) {
    this.playerService.increaseVolume();
    //}
  }

  ngOnSlideChange(value: any): void {
    if (this.playerService.getWavesurfer()) {
      this.volume.nativeElement.value = this.volume.nativeElement.value;
      this.playerService.setVolume(this.volume.nativeElement.value / 100);
    } else {
      this.playerService.setVolume(this.volume.nativeElement.value / 100);
    }
  }

  formatLabelVolume(value: number): string {
    if (value >= 1) {
      return value + '%';
    }
    return `${value}`;
  }

  toggleLoop(): void {
    if (this.playerService.getWavesurfer()) {
      this.playerService.toggleLoop();
      this.loopActive = !this.loopActive;
    }
  }

  getLoop(): boolean {
    if (this.loopActive) {
      return true;
    } else {
      return false;
    }
  }

  /*updateTime() {
    const currentTime = this.playerService.getWavesurfer().getCurrentTime();
    const currentMinutes = Math.floor(currentTime / 60);
    const currentSeconds = Math.floor(currentTime % 60);
    this.currentTime = `${currentMinutes}:${currentSeconds < 10 ? '0' : ''}${currentSeconds}`;

    const totalMinutes = Math.floor(this.playerService.getWavesurfer().getDuration() / 60);
    const totalSeconds = Math.floor(this.playerService.getWavesurfer().getDuration() % 60);
    this.totalTime = `${totalMinutes}:${totalSeconds < 10 ? '0' : ''}${totalSeconds}`;

    this.currentTimeSlider = (currentTime * 100) / this.playerService.getWavesurfer().getDuration();

    if (this.currentTime == this.totalTime && !this.loopActive) {
      this.setFinish();
    }
  }*/

  setFinish(): void {
    this.playerService.stop();
    this.isPlaying = false;
    this.currentTimeSlider = 0;
    this.currentTime = '0:00';
    if (!this.loopActive) {
      //this.setCoverBottom(0);
      this.isCoverShowed = false;
    } else {
      this.playerService.play();
      this.isPlaying = true;
    }
  }

  ngOnSlideChangeTime(value: any): void {
    if (this.playerService.getWavesurfer()) {
      this.playerService.setTime((this.time.nativeElement.value * this.playerService.getWavesurfer().getDuration()) / 100);
    }
  }
}
