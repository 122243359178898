<mat-sidenav-container class="sidenav-container disable-selection">
    <mat-sidenav mode="side" mode="over" [(opened)]="sidenavService.opened"
        [style.display]="!isScreenSmall() ? 'none' : 'block'" class="sidenav-container__sidenav">
        <mat-list class="sidenav-container__sidenav__list">
            <mat-list-item class="sidenav-container__sidenav__list__item" matRipple
                (click)="handleListItemClick('/es', '/en')"
                (keyup.enter)="handleListItemClick('/es', '/en')"
                [routerLinkActiveOptions]="{ exact: true }" [routerLink]="getLanguage() == 'es' ? '/es' : '/en'"
                routerLinkActive="selected">
                <span class="sidenav-container__sidenav__list__item__span">{{'SIDENAV.HOME.TITLE' | translate}}</span>
            </mat-list-item>
            <mat-list-item class="sidenav-container__sidenav__list__item" matRipple
                (click)="handleListItemClick('/es/acerca', '/en/about')"
                (keyup.enter)="handleListItemClick('/es/acerca', '/en/about')"
                [routerLinkActiveOptions]="{ exact: true }"
                [routerLink]="getLanguage() == 'es' ? '/es/acerca' : '/en/about'" routerLinkActive="selected">
                <span class="sidenav-container__sidenav__list__item__span">{{'SIDENAV.ABOUT.TITLE' | translate}}</span>
            </mat-list-item>
            <mat-list-item class="sidenav-container__sidenav__list__item" matRipple
                (click)="handleListItemClick('/es/lanzamientos', '/en/releases')"
                (keyup.enter)="handleListItemClick('/es/lanzamientos', '/en/releases')"
                [routerLinkActiveOptions]="{ exact: true }"
                [routerLink]="getLanguage() == 'es' ? '/es/lanzamientos' : '/en/releases'" routerLinkActive="selected">
                <span class="sidenav-container__sidenav__list__item__span">{{'SIDENAV.RELEASES.TITLE' |
                    translate}}</span>
            </mat-list-item>
            <mat-list-item class="sidenav-container__sidenav__list__item" matRipple
                (click)="handleListItemClick('/es/contacto', '/en/contact')"
                (keyup.enter)="handleListItemClick('/es/contacto', '/en/contact')"
                [routerLinkActiveOptions]="{ exact: true }"
                [routerLink]="getLanguage() == 'es' ? '/es/contacto' : '/en/contact'" routerLinkActive="selected">
                <span class="sidenav-container__sidenav__list__item__span">{{'SIDENAV.CONTACT.TITLE' |
                    translate}}</span>
            </mat-list-item>
        </mat-list>
    </mat-sidenav>
    <mat-sidenav-content  [@routeAnimations]="getState(o)" class="sidenav-container__content"><!--[@fadeAnimation]="prepareRouteTransition()"-->
        <router-outlet #o="outlet"></router-outlet>     
        <app-player></app-player>   
    </mat-sidenav-content>    
</mat-sidenav-container>