// Importa el módulo Injectable desde '@angular/core'
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root', // Proporciona el servicio a nivel de la aplicación
})
export class LocalStorageService {
  private onLoadlocalStorageData: boolean = false;
  constructor() { }

  // Método para guardar datos en localStorage
  setItem(key: string, value: any): void {
    if (typeof localStorage !== 'undefined') {
      const serializedValue = JSON.stringify(value);
      localStorage.setItem(key, serializedValue);
    }
  }
  // Método para leer datos desde localStorage
  getItem<T>(key: string): T | null {
    if (typeof localStorage !== 'undefined') {
      const serializedValue = localStorage.getItem(key);
      if (serializedValue) {
        if(!this.onLoadlocalStorageData){
          this.onLoadlocalStorageData = true;
        }        
        return JSON.parse(serializedValue) as T;
      }      
    }
    return null;
  }
  // Método para actualizar datos en localStorage
  updateItem(key: string, updateFn: (value: any) => any): void {
    if (typeof localStorage !== 'undefined') {
      const existingValue = this.getItem(key);
      if (existingValue) {
        const updatedValue = updateFn(existingValue);
        this.setItem(key, updatedValue);
      }
    }
  }
  getOnLoadlocalStorageData(): boolean {
    return this.onLoadlocalStorageData;
  }
}