import {  Component, Inject } from '@angular/core';
import { MaterialModule } from '../../material.module';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';

export interface DialogData {
  cover: string;
}

@Component({
  selector: 'app-modal-cover',
  templateUrl: './modal-cover.component.html',
  styleUrl: './modal-cover.component.scss',
  standalone: true,
  imports: [
    MaterialModule,
    CommonModule
  ],
})
export class modalCoverComponent {
  constructor(
    public dialogRef: MatDialogRef<modalCoverComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
  }
  close(): void {
    this.dialogRef.close();
  }
}
