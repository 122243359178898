<a aria-label="enlace" class="logo" (click)="handleListItemClick('/es', '/en');"
    (keyup.enter)="handleListItemClick('/es', '/en')" [routerLinkActiveOptions]="{ exact: true }"
    [routerLink]="getLanguage() == 'es' ? '/es' : '/en'" routerLinkActive="selected">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 540 102" class="svg">
        <g class="svg__fill">
            <path
                d="M45.5,9H20.2C19.3,9,19,9.8,19,10.7v71.6c0,0.9,0.3,1.7,1.2,1.7H45.5C46.4,84,47,83.2,47,82.3V45.6 10.7C47,9.8,46.5,9,45.5,9Z" />
            <path
                d="M399.9,93.2c0.9,0,2.1,-0.7,2.1,-1.7V84h92.6 18.2 6.2c0.9,0,2.1,-0.8,2.1,-1.7V10.7C521.1,9.8,519.9,9,519,9h-3.7-20.7-94.6-25.9-32.5-25.3-33.7-22.3-3  C256.4,9,256,9.8,256,10.7V68H200.5L165,49.7v-39C165,9.8,164.6,9,163.6,9H138.3C137.4,9,137,9.7,137,10.7V32.9 78c0,0.8,0.6,1.2,1.9,1.9 0.2,0.1,1,0.5,1.2,0.6l23,11.7c0.8,0.4,1.8,0.1,1.9,-0.8 0,-0.3,0,-0.4,0,-0.6V70l27.3,14h65.1 0.9 24.4c0.9,0,1.3,-0.8,1.3,-1.7V24h31v58.3c0,0.9,0.4,1.7,1.3,1.7h25.3c0.9,0,1.3,-0.8,1.3,-1.7V24h29v58.3c0.2,1.1,1.3,1.4,2.3,1.7  M402,24h91v46h-91z" />
            <path d="M136.6,33H74L47.2,46h63.1z" />
        </g>
    </svg>
</a>