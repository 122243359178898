import { Component } from '@angular/core';
import { SidenavService } from '../../../@core/services/sidenav.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrl: './logo.component.scss'
})
export class LogoWhiteComponent {
  constructor(
    public sidenavService: SidenavService,
    private translateService: TranslateService,
    private router: Router
  ) {

  }

  public closeSidenav(): void {
    this.sidenavService.close();
  }

  public navigate(route: string) {
    this.router.navigateByUrl(route);
  }

  public getLanguage(): string {
    return this.translateService.currentLang;
  }
  handleListItemClick(routeEs: string, routeEn: string) {
    this.closeSidenav();
    if (this.getLanguage() == 'es') {
      this.navigate(routeEs);
    } else {
      this.navigate(routeEn);
    }
  }
}
