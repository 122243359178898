import { Component, Inject } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatButton, MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIcon } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MAT_SNACK_BAR_DATA, MatSnackBarAction, MatSnackBarActions, MatSnackBarLabel, MatSnackBarRef } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'snackbar-component',
    templateUrl: './snackbar.component.html',
    styleUrl: './snackbar.component.scss',
    imports: [MatFormFieldModule, FormsModule, MatIcon, MatInputModule, MatButtonModule, MatSnackBarActions, MatSnackBarAction, MatButton, MatSnackBarLabel],
    standalone: true,
  })
  export class SnackBarComponent {
    constructor(
        public snackBarRef: MatSnackBarRef<SnackBarComponent>,
        @Inject(MAT_SNACK_BAR_DATA) public data: any,
        public translateService: TranslateService,
        private router: Router
      ) {}

    
      goToReleases(){
        if (this.getLanguage() == 'es') {
          this.router.navigateByUrl('/es/lanzamientos');
        } else {
          this.router.navigateByUrl('/en/releases');
        }
      }
    
      public getLanguage(): string {
        return this.translateService.currentLang;
      }
  }