import { AfterContentInit, AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '../../@core/services/local.storage.service';
import { DOCUMENT } from '@angular/common';
import { LanguageService } from '../../@core/services/language.service';
import { Subscription } from 'rxjs';

// Definir un tipo para el mapeo
type PathMappings = {
  [key: string]: string;
};

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent implements OnInit, AfterViewInit {
  public language: string | null = '';
  private extractedPath: string = '';
  private languageSubs: Subscription = new Subscription();

  constructor(
    private translateService: TranslateService,
    private localStorageService: LocalStorageService,
    private languageService: LanguageService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document
  ) {

  }

  ngOnInit(): void {
    this.languageSubs = this.languageService.languageObs.subscribe(lang => {
      this.language = lang;
    })
  }

  ngAfterViewInit(): void {
    this.language = this.translateService.getDefaultLang();
    const preloadTransitions = this.document.getElementById('preload');
    preloadTransitions?.classList.remove("preload");
  }

  public setLanguage(lang: string): void {
    if(lang != this.translateService.currentLang){
      this.language = lang;
      this.translateService.setDefaultLang(lang);
      this.translateService.use(lang);
      const urlSegments = this.router.url;
      let extractedPath = urlSegments.split('/');
      extractedPath.splice(0,1);
  
      // Verificar si la palabra clave está en el mapeo
      const mappings: PathMappings = {
        'about': 'acerca',
        'releases': 'lanzamientos',
        'contact': 'contacto',
        'en': 'es',
        'acerca': 'about',
        'lanzamientos': 'releases',
        'contacto': 'contact',
        'es': 'en'
      };
  
      const redirectPath = mappings[extractedPath[1]] || lang;
      // Construir la ruta y redirigir
      let route = lang + '/' + (extractedPath.length > 1 ? redirectPath : '');
      route = route.replace(/\/$/, '')
      this.localStorageService.setItem('lang', this.translateService.getDefaultLang());
      this.languageService.setLanguageSubject(this.translateService.getDefaultLang());
      this.router.navigateByUrl(route);
    }
    

  }

}
