// language.guard.ts
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { LocalStorageService } from '../services/local.storage.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../services/language.service';

@Injectable({
  providedIn: 'root',
})
export class OnloadESGuard implements CanActivate {
  constructor(
    private localStorageService: LocalStorageService, 
    private router: Router, 
    private translateService: TranslateService, 
    private languageService: LanguageService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.translateService.setDefaultLang('es');
    this.translateService.use('es');
    this.localStorageService.setItem('lang', 'es');
    this.languageService.setLanguageSubject('es');
    return true;
  }
}
