import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SidenavService {
    public opened: boolean = false;

    constructor() {

    }
    public sidenavToggle(): void {
        this.opened =! this.opened;
    }
    public close(): void{
        this.opened = false;
    }

}