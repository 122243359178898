import { AfterViewInit, ChangeDetectorRef, Component, HostListener, Inject } from '@angular/core';
import { SidenavService } from '../../@core/services/sidenav.service';
import { DOCUMENT } from '@angular/common';
import { MediaMatcher } from '@angular/cdk/layout';
import { TranslateService } from '@ngx-translate/core';
import { ChildrenOutletContexts, Route, Router, RouterOutlet } from '@angular/router';
import { slideInAnimation } from '../../animations';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrl: './sidenav.component.scss',
  animations: [
    slideInAnimation
  ]
})
export class SidenavComponent implements AfterViewInit {
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  constructor(
    public sidenavService: SidenavService,
    private translateService: TranslateService,
    @Inject(DOCUMENT) private document: Document,
    changeDetectorRef: ChangeDetectorRef, 
    media: MediaMatcher,
    private router: Router,
    private contexts: ChildrenOutletContexts

  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }
  getState(outlet: RouterOutlet) {
    // Changing the activatedRouteData.state triggers the animation
    return outlet.activatedRouteData['state'];
  }
  getRouteAnimationData() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
  }
  ngAfterViewInit() {
    const backdrop = this.document.querySelector('.mat-drawer-backdrop');
    if (backdrop) {
      backdrop.addEventListener('click', () => {
        this.sidenavService.close();
      });
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    // Manejar el evento de cambio de tamaño aquí
    if (window.innerWidth > 600) {
      this.sidenavService.close();
    }
  }
  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  public isScreenSmall(): boolean {
    return this.mobileQuery.matches;
  }

  public closeSidenav(): void {
    this.sidenavService.close();
  }

  public navigate(route: string) {
    this.router.navigateByUrl(route);
  }
  
  public getLanguage(): string {
    return this.translateService.currentLang;
  }
  handleListItemClick(routeEs: string, routeEn: string) {
    this.closeSidenav();
  
    if (this.getLanguage() == 'es') {
      this.navigate(routeEs);
    } else {
      this.navigate(routeEn);
    }
  }
  isActive(route: string): boolean {
    return this.router.url === route || this.elementHasSelectedClass();
  }
  
  elementHasSelectedClass(): boolean {
    const selectedElement = this.document.querySelector('.sidenav-container__sidenav__list__item.selected');
    return !!selectedElement;
  }
}
