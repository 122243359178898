import { NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { MaterialModule } from './material.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule, provideHttpClient, withFetch } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LogoWhiteComponent } from './@components/svg/logo/logo.component';
import { LanguageComponent } from './@components/language/language.component';
import { ToolbarComponent } from './@components/toolbar/toolbar.component';
import { SidenavComponent } from './@components/sidenav/sidenav.component';
import { WebpackTranslateLoader } from '../../webpack-translate-loader';
import { PlayerComponent } from './@components/player/player.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LinksSharedModule } from './@components/links/links-shared.module';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LogoWhiteComponent,
    LanguageComponent,
    ToolbarComponent,
    SidenavComponent,
    PlayerComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MaterialModule,
    HttpClientModule,
    LinksSharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      }
    }),
    
  ],
  providers: [    
    provideHttpClient(withFetch()),
    provideClientHydration(),
    provideAnimationsAsync()
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
