<div class="snackbar-wrap">
    <img class="snackbar-wrap__cover" [src]="data.data.cover" (click)="goToReleases()"  alt="Cover"/>
    <div class="snackbar-wrap__data" matSnackBarLabel>
        <p class="snackbar-wrap__data__title">{{data.data.title}}</p>
        <p class="snackbar-wrap__data__artist">{{data.data.artist}}</p>
        <p class="snackbar-wrap__data__label">{{data.data.label}}</p>
    </div>
    <span matSnackBarActions class="snackbar-wrap__button">
        <button aria-label="Button" mat-icon-button matSnackBarAction color="primary" (click)="snackBarRef.dismissWithAction()"><mat-icon>close</mat-icon></button>
    </span>
</div>
