// language.guard.ts
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { LocalStorageService } from '../services/local.storage.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../services/language.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageGuard implements CanActivate {
  constructor(private localStorageService: LocalStorageService, private router: Router, private translateService: TranslateService, private languageService: LanguageService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let language = this.localStorageService.getItem('lang');
    if(!language){
      this.localStorageService.setItem('lang', 'en');
      language = 'en';
    }
    // Verificar si la clave de idioma es 'es' o 'en' y redirigir según el caso
    if (language === 'es') {      
      this.router.navigateByUrl('es');
      return true;
    } else if (language === 'en') {      
      this.router.navigateByUrl('en');
      return true;
    } 
    return true;
  }
}
