import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component } from '@angular/core';
import { SidenavService } from '../../@core/services/sidenav.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrl: './toolbar.component.scss',
  animations: [
    trigger('menubtn', [
      state('true', style({
        transform: 'rotate(90deg)',
        color: 'rgba(255,255,255,1)'

      })),
      state('false', style({
        transform: 'rotate(0deg)',
        color: 'rgba(255,255,255,1)'
      })),
      // transition
      transition('* => *', animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)')),
    ])
  ]
})
export class ToolbarComponent implements AfterViewInit {
  public viewInit: boolean = false;
  constructor(
    public sidenavService: SidenavService,
    private translateService: TranslateService,
    private router: Router
  ) {

  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.viewInit = true;
    }, 100);
  }
  sidenavToggle() {
    this.sidenavService.sidenavToggle();
  }
  public navigate(route: string) {
    this.router.navigateByUrl(route);
  }

  public getLanguage(): string {
    return this.translateService.currentLang;
  }
  handleListItemClick(routeEs: string, routeEn: string) {
    if (this.getLanguage() == 'es') {
      this.navigate(routeEs);
    } else {
      this.navigate(routeEn);
    }
  }
}
