import { animate, animateChild, group, query, style, transition, trigger } from "@angular/animations";

const rightToLeft = [
  style({ position: 'relative' }),
  query(':enter, :leave', [
    style({
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%'          
    })
  ], { optional: true }),
  query(':enter', [
    style({ left: '-100%' })
  ], { optional: true }),
  query(':leave', animateChild(), { optional: true }),
  group([
    query(':leave', [
      animate('1000ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({ left: '100%', opacity: 0 }))
    ], { optional: true }),
    query(':enter', [
      animate('1000ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({ left: '0%' }))
    ], { optional: true }),
    query('@*', animateChild(), { optional: true })
  ])
];
const leftToRight = [
  style({ position: 'relative' }),
  query(':enter, :leave', [
    style({
      position: 'absolute',
      top: 0,
      right: 0,
      width: '100%',
      height: '100%'          
    })
  ], { optional: true }),
  query(':enter', [
    style({ right: '-100%' })
  ], { optional: true }),
  query(':leave', animateChild(), { optional: true }),
  group([
    query(':leave', [
      animate('1000ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({ right: '100%', opacity: 0 }))
    ], { optional: true }),
    query(':enter', [
      animate('1000ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({ right: '0%' }))
    ], { optional: true }),
    query('@*', animateChild(), { optional: true })
  ]),
];
export const slideInAnimation =
  trigger('routeAnimations', [
    transition('en => about', leftToRight),
    transition('about => releases', leftToRight),
    transition('about => contact', leftToRight),
    transition('about => en', rightToLeft),
    transition('releases => about', rightToLeft),
    transition('contact => about', rightToLeft),
   
    transition('en => releases', leftToRight),
    transition('releases => contact', leftToRight),
    transition('releases => en', rightToLeft),
    transition('contact => releases', rightToLeft),

    transition('en => contact', leftToRight),
    transition('contact => about', rightToLeft),
    transition('contact => en', rightToLeft),
    transition('contact => releases', rightToLeft),

    transition('es => acerca', leftToRight),
    transition('acerca => lanzamientos', leftToRight),
    transition('acerca => contacto', leftToRight),
    transition('acerca => es', rightToLeft),
    transition('lanzamientos => acerca', rightToLeft),
    transition('contacto => acerca', rightToLeft),
   
    transition('es => lanzamientos', leftToRight),
    transition('lanzamientos => contacto', leftToRight),
    transition('lanzamientos => es', rightToLeft),
    transition('contacto => lanzamientos', rightToLeft),

    transition('es => contacto', leftToRight),
    transition('contacto => acerca', rightToLeft),
    transition('contacto => es', rightToLeft),
    transition('contacto => lanzamientos', rightToLeft)
   
  ]);